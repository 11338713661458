html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#page-404 * {
  transition: all 0.6s;
}

#page-404 {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  color: #888;
}

#page-404 .fof {
  display: table-cell;
  vertical-align: middle;
}

#page-404 .fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
  font-family: "Lato", sans-serif;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

#survey {
  width: 100vw;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#survey .logo {
  text-align: center;
  height: 100px;
}

#survey .logo img {
  height: 100px;
  width: 100%;
  object-fit: contain;
  padding: 0;
  margin: 0;
}

#survey .title {
  text-align: center;
  width: 100%;
  font-size: 1.5em;
  font-weight: 500;
  padding-top: 15px;
  color: rgb(50, 142, 205);
}

#survey .progress {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

#survey .progress .bar {
  height: 10px;
  border-radius: 10px;
  background: rgb(50, 142, 205);
  transition: 0.3s;
  flex: 1 0 0;
  margin: 0 2%;
}

#survey .progress .bar.active {
  flex: 2 0 0;
  background: rgb(148, 235, 255);
}

#survey .question {
  width: 100%;
  text-align: center;
  line-height: 1.5;
  color: #464149;
  font-size: 18px;
}

#survey .powered {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  padding-bottom: 17px;
}

#survey .powered img {
  width: 50px;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 5px;
}

#survey .footer .buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

#survey .footer .next-button {
  flex: 2 0 0;
  text-align: center;
  transition: 0.3s;
  padding: 20px;
  color: #f2bf30;
  cursor: pointer;
}

#survey .footer .prev-button {
  flex: 1 0 0;
  text-align: center;
  transition: 0.3s;
  padding: 20px;
  color: #2aa1d3;
  border-right: 1px solid #ddd;
  cursor: pointer;
}

#survey .footer .next-button.active {
  background: #f2bf30;
  color: #fff;
}

#survey .footer .item {
  width: 100%;
  padding: 15px 15px 15px calc(33% + 20px);
  background: #eaf5fb;
  color: #464149;
  font-size: 14px;
  transition: 0.3s;
  border-top: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

#survey .footer .item.active {
  background: #2aa1d3;
  color: #fff;
}

#survey .footer .check {
  width: 20px;
  height: 20px;
  position: absolute;
  left: calc(33% - 10px);
  top: calc(50% - 10px);
}

.checkmark-1 {
  stroke: #eaf5fb;
  transition: 0.3s;
  fill: none;
}

.checkmark-2 {
  fill: #eaf5fb;
  transition: 0.3s;
}

.checkmark-3 {
  stroke: none;
}

.checkmark-4 {
  fill: none;
}

textarea {
    width: 100vw;
    min-height: 100px;
    height: 30vh;
    padding: 10px;
    margin: 0
}

.slider {
  padding: 20px 30px;
}